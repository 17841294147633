/* .heroSection {
    height: 600px ; 
    background-image: url("../../Assets/images/Hero/2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;

} */


/* .spotlightSection_item{
    background-image: url("../../Assets/images/Hero/5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
} */

.footer{
    height: 600px ; 
}


.hide {
    visibility: hidden;
}
 