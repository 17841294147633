.clearBorder {
    /* outline:none !important; */
    box-shadow: none !important;
    border: none;
}


.DisplayOnMainContainerClick{
    visibility: visible;
}

.HideOnMainContainerClick{
    visibility: hidden;
}