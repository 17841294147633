.HeightOfDashBoard {
    width: 100%;
    min-height: calc(100vh - 80px);
    padding: 10px;
    overflow: auto;
}

.BG_Color_DashBoard {
    width: 100%;
    min-height: calc(100vh - 80px);
    padding: 10px;
    border-radius: 10px;
    overflow-y: auto;
    background-color: white;
}