.rbc-today {
    background-color: #e7c498 !important ;
}
.rbc-day-bg:hover {
    cursor: pointer;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #ffffff;
    background-color: #dacbb9;
}

.ModalWidth {
    width:  75%;
    opacity: 1;
}
.RemoveModalWidth {
    width:  0;
    opacity: 0;
}
