.progressBar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .label, .completedLabel {
    color: #4A5568; 
  }
  
  .barContainer {
    position: relative;
    width: 100%;
    height: 0.5rem;
    background-color: #E2E8F0; 
    border-radius: 0.25rem; 
    background-color: #e0e0e0;
    border-radius: 9999px;
    overflow: hidden;
  }
  
  .bar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 0.25rem; 
    background-image: linear-gradient(to right, #68D391, #63baed); 
    transition: width 0.05s linear; 
  }
  
  .labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.completedLabel {
  font-weight: bold;
  color: #4299e1; /* Match this with your bar color */
}