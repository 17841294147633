.Csvg {
    filter: opacity(0)
    /* filter: invert(72%) sepia(52%) saturate(3803%) hue-rotate(82deg) brightness(97%) contrast(93%); */
}

.CsvgColor {

     filter: opacity(1)
}

.path {
    stroke-dasharray: 1453.450439453125;
    stroke-dashoffset: 1453.450439453125;
    /* animation: dash 1s ease-in-out forwards; */

}

.pathAnimate {
    stroke-dashoffset: 1453.450439453125;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1453.450439453125;
    }

    to {
        stroke-dashoffset: 0;
    }
}



.fadeInAnimate{
    opacity: 1;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.wait 
{
    cursor: wait;
}
