@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .headerText {
    @apply text-[14px] text-[#035191];
  }

  .BigLoader {
    @apply w-20 h-20 animate-spin border-8 border-t-8 border-t-[#FF590B] rounded-[50%] hide
  }

  .CardH {
    @apply flex flex-wrap gap-6 items-center justify-between bg-[#fCfCfC] shadow-md p-2 rounded-lg my-2
  }
}

@layer utilities {
  .pauseAnimation {
    animation-play-state: paused;
  }

  .primary {
    color: #000;
    background-color: #000;
  }

  .secondary {
    color: #e7c498;
    background-color: #e7c498;
  }


  .primaryText {
    color: #000;
  }

  .primaryBG {
    background-color: #000;
  }

  .secondaryText {
    color: #e7c498;
  }

  .secondaryBG {
    background-color: #e7c498;
  }


  .blue-dark {
    background-color: #002F3F;
  }

  .orange-dark {
    background-color: #FF590B;
  }

  .loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;

  }

  .hide {
    visibility: collapse;
  }
}

input,
input:focus,
textarea {
  outline: 1px solid transparent !important;
  outline-offset: 0 !important;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: rgb(218, 218, 218);
  background: linear-gradient(90deg, rgba(218, 218, 218, 1) 0%, rgba(214, 214, 214, 1) 40%, rgba(255, 255, 255, 0) 50%, rgba(214, 214, 214, 1) 60%, rgba(218, 218, 218, 1) 100%);
  background-size: 200% 200%;
  animation: shimmer 3s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap'); */


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */