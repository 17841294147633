.RotateBy360{
    transform-origin: center;
    animation: RotateBy360Anim .25s linear forwards   ;
  }
  
  @keyframes RotateBy360Anim {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }