@media screen {
    .print-only {
        display: none;
    }
}

@media print {
    @page {
        size: A4 portrait;
    }

    /* hide scrollbar */
    .gridContainer {
        overflow: visible !important;
        /* This will make the content extend beyond its container if it's too large, ensuring no scrollbar is needed */
    }

    .Break {
        page-break-after: always;
    }

}