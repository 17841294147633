.LoadingSC {
    background-color: rgba(235, 236, 236, .8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
    width: 100%;
    height: 100%;
}


#LogoAnim {
    stroke:none;
    stroke-width: 10px;
    opacity: 0;
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    animation: Draw 1.5s infinite;
}

/* #LogoAnim > path {
    stroke-dashoffset: 300; 
    stroke-dasharray: 10;
    animation: Draw 3s reverse;
} */


@keyframes Draw {
    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}