.TopIsZero {
    top: 25%;
    /* background-color: blue */
}

.TopIsZero_Remove {
    top: 75%
}


.LeftIsZero {
    left: 0;
    /* background-color: blue */
}

.LeftIsZero_Remove {
    left: 100%;
    /* background-color: blue */
}

.PaymentLeftisZero {
    left: 25%;
}

.RemovePaymentLeftisZero {
    left: 100%;
}
.PaymentHidden {
    display: none;
}

.PaymentVisible {
    display: block;
}