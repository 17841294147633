.rbc-event{
    background-color:  transparent !important;
    text-align: center !important;
    text-overflow: clip !important ; 
    border:  0px;
}


.rbc-event-label {
    font-size: 0;
}

element.style {
    margin-right: -9px;
}

.Show {
    left : 10%
}
.Hide {
    left : 100%
}