.showTransModal {
    left: 12.5%; 
}


.hideTransModal {
    left: 100%;
}


.showEquality {
    left: 0;
}


.hideEquality {
    left: 100%;
}