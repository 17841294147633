.gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 80vh;
}

.gridItem {
    background-color: aqua;
}

.gridRow {
    grid-column: 1 / -1;
    background-color: burlywood;
}

.header {
    grid-column: 1 / -1;
    background-color: #e7c498;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
    border: 2px solid black;
}

.box {
    padding: 1rem;
    border: 2px solid black;
    grid-column: 1 / -1;
}

.fullRow{
    grid-column: 3 /5;
}

.displayModal {
    left: 0;
}

.hideModal {
    left: 250%;
}