.sliderAnimation {
    position: relative;
    animation: FromRightToLeft 1s infinite forwards ease-in-out;
}

@keyframes FromRightToLeft {
    from {
        left: 0
    }

    to {
        left: 1000px
    }
}

@keyframes scroll {
    0% {
        left: 0%;
    }

    100% {
        left: -100%;
    }
}

.animate {
    animation: scroll 30s  linear infinite;
}

.pauseAnimate {
    animation-play-state: paused;
  }

.clickabelSLider {
    transition: left .5s ease-in-out;
}

