.show {
    left: 12.5%
}


.hide {
    left: 100%
}
.showEquality {
    left: 0
}


.hideEquality {
    left: 110%
}