html {
  /* font-family: sans-serif; */
    font-size: 14px;
  }
  
  table {
    border: 1px solid lightgray;
  }
  
  tbody {
    border-bottom: 1px solid lightgray;
  }

  th {
    text-align: center;
    border: #002F3F 1px solid;
    padding: 1em;
  }

  td {
    text-align: center;
    border: #002F3F 1px solid;
    padding: 1em;
  }

  thead th:last-child {
    width: 10em;
  }
  
  tfoot {
    color: gray;
  }
  
  tfoot th {
    font-weight: normal;
  }


  /* #table_Container tr th:last-child{ border-right: 1px solid red; border-left:1px solid red}
  #table_Container tr td:last-child{ border-right: 1px solid red; border-left:1px solid red}
  #table_Container tr:last-child td:last-child{border-bottom:1px solid red} */
  /* #table_Container tr td{border: 1px solid #002F3F}
  #table_Container th td{border: 1px solid #002F3F} */